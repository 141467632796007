import { LOGIN_USER, GET_ERRORS } from "../constants/user";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwtDecode from "jwt-decode";
import {SERVER} from "../constants/config";

export const requestLoginUSer = userData => {
  // console.log(userData)
  const params = {
    username: userData.username,
    password: userData.password,
    fingerprint: userData.fingerprint
  }
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  return dispatch => {
    axios
      .get(`${SERVER}/user/login`, {
        headers: headers,
        params: params
      })
      .then(res => {
        // console.log(res.data.detail)
        if (typeof res.data.detail !== "string") {
          const { token } = res.data.detail;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("userLogin", JSON.stringify(res.data.detail));
          // console.log('set Item Ok')
          setAuthToken(token);          
          // console.log('set Token Ok')
          const decoded = jwtDecode(token);      
          // console.log(decoded)    
          dispatch(actLoginUser(decoded));
          // console.log('decoded Ok')
          //   callback();
        } else {
          alert("Tài khoản hoặc mật khẩu sai gòi.");
        }
      })
      .catch(err => {
        // console.log(err.response.data.detail)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.detail
        });
      });
  };
};
export const insertUser = (username, password, user_type,employee_code, callback) => {
  const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("jwtToken"),
      fingerprint: "123456",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
    };  
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const params = {
    EditBy,
    username,
    password,
    user_type,
    employee_code,
  }

  return dispatch => {
    axios
      .get(`${SERVER}/user/insert`,{
        params: params,
        headers: headers
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}  

export const updateUserRole = (user_type,employee_code, callback) => {
  const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("jwtToken"),
      fingerprint: "123456",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
    };  
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const params = {
    EditBy,
    user_type,
    employee_code,
  }

  return dispatch => {
    axios
      .get(`${SERVER}/user/update/role`,{
        params: params,
        headers: headers
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}  
export const actLoginUser = decoded => {
  return {
    type: LOGIN_USER,
    payload: decoded
  };
};

export const insertUserPri = (dataUserPri, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const params = {
    EditBy: EditBy,
    user_type: dataUserPri.user_type,
    reason: dataUserPri.reason,
    tq_pri: dataUserPri.inline_radios_tq,
    ts_pri: dataUserPri.inline_radios_ts,
    nd_pri: dataUserPri.inline_radios_nd,
    tb_pri: dataUserPri.inline_radios_tb,
    mh_pri: dataUserPri.inline_radios_mh,
    lh_pri: dataUserPri.inline_radios_lh,
    vp_pri: dataUserPri.inline_radios_vp,
    zl_pri: dataUserPri.inline_radios_zl,
    ht_pri: dataUserPri.inline_radios_ht,
    access_app: dataUserPri.access_app,
    sd_pri: dataUserPri.inline_radios_sd,
    anninh: dataUserPri.anninh,
  }
  
  const isUpdatePri = dataUserPri.isUpdatePri  

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  return dispatch => {
    axios
      .get(`${SERVER}/user/privilege/insert`, {
        headers: headers,
        params: params
      })
      .then(res => {

        callback(res.data);
      })
      .catch(console.log);
  };
};

export const registerUser = (userData, history, callback) => {
  const params = {
    username: userData.username,
    password: userData.password,
    employee_code: userData.employee_code,
    fingerprint: "123456",
    //email: userData.email,
    //full_name: userData.full_name,
    //phone: userData.phone,
    user_type: userData.user_type,
    //user_position: userData.user_position,
    //employee_name: userData.employee_name,
    //ManagerCode: userData.ManagerCode,
    //DeptCode: userData.DeptCode,
    //DeptInChargeCode: userData.DeptInChargeCode,
    EmpSelected: userData.EmpSelected
  }

  // console.log(userData)
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code

  const formData = new FormData();
  formData.append("EditBy",EditBy);
  formData.append("username", userData.username);
  formData.append("password", userData.password);
  formData.append("fingerprint", '123456');
  //formData.append("email", userData.email);
  //formData.append("phone", userData.phone);
  formData.append("user_type", userData.user_type);
  //formData.append("user_position", userData.user_position);
  //formData.append("employee_name", userData.employee_name);
  //formData.append("employee_code", userData.employee_code);
  //formData.append("ManagerCode", userData.ManagerCode);
  //formData.append("DeptCode", userData.DeptCode);
  //formData.append("DeptInChargeCode", userData.DeptInChargeCode);
  formData.append("EmpSelected", userData.EmpSelected);
  
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  // console.log(params)

  return dispatch => {
    axios
      .post(
        `${SERVER}/user/register`,formData,
        {
          headers: headers,
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};

export const updateUserPW = (userData, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const updated_by = decoded.employee_code
  const params = {
    password: userData.password,
    employee_code: userData.employee_code,    
    updated_by: updated_by
  }
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  // console.log(params)

  return dispatch => {
    axios
      .get(
        `${SERVER}/user/updatepw`,
        {
          headers: headers,
          params: params
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};

export const UpdateUserPri = (userData, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const updated_by = decoded.employee_code
  const params = {    
    employee_code: userData.employee_code,    
    user_type: userData.user_type,
    position_code: userData.user_position,
    updated_by: updated_by
  }

  // console.log(params)
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  // console.log(params)

  return dispatch => {
    axios
      .get(
        `${SERVER}/user/updatepri`,
        {
          headers: headers,
          params: params
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};


export const updateUserPWDetail = (userData, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const updated_by = decoded.employee_code
  const params = {    
    password: userData.password,
    employee_code: userData.employee_code,    
    currentpassword: userData.currentpassword,
    updated_by: updated_by
  }
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  // console.log(params)

  return dispatch => {
    axios
      .get(
        `${SERVER}/user/updatepw/detail`,
        {
          headers: headers,
          params: params
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(err.response.data.detail)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.detail
        });
      });
  };
};

export const deactivateUser = (userData, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const updated_by = decoded.employee_code
  const params = {
    employee_code: userData,    
    updated_by: updated_by
  }
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  // console.log(params)

  return dispatch => {
    axios
      .get(
        `${SERVER}/user/deactivate`,
        {
          headers: headers,
          params: params
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};

export const activateUser = (userData, callback) => {
  const decoded = jwtDecode(localStorage.jwtToken)
  const updated_by = decoded.employee_code

  const params = {
    employee_code: userData,    
    updated_by: updated_by
  }
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  return dispatch => {
    axios
      .get(
        `${SERVER}/user/activate`,
        {
          headers: headers,
          params: params
        }
      )
      .then(res => {
        callback(res.data);
        // console.log(large)
        // history.push("/");
        // return <Redirect to='/users' />
      })
      .catch(err => {
        // console.log(large)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
      });
  };
};

export const fetchMaxemp = callback => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456"
  };

  return dispatch => {
    axios
      .get(`${SERVER}/employee/fetch/max`, null, {
        headers: headers
      })
      .then(res => {
        callback(res.data);
      })
      .catch(console.log);
  };
}

export const fetchAllUserPri = callback => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456"
  };

  return dispatch => {
    axios
      .get(`${SERVER}/user/privilege/fetch`, null, {
        headers: headers
      })
      .then(res => {

        callback(res.data);
      })
      .catch(console.log);
  };
};

export const deleteUserPri = (user_privileges_id, callback) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code

  const params = {
    EditBy,
    user_privileges_id
  }

  return dispatch => {
    axios
      .get(`${SERVER}/user/privilege/delete`, {
        headers: headers,
        params: params
      })
      .then(res => {

        callback(res.data);
      })
      .catch(console.log);
  };
};

export const fetchAllUser = callback => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const is_manager = decoded.is_manager
  const employee_code = decoded.employee_code
  const is_hr= decoded.is_hr
  const department_in_charge_id= decoded.department_in_charge_id

  const params = {
    department_in_charge_id,
    employee_code,
    is_hr,
    is_manager
  }
  return dispatch => {
    axios
      .get(`${SERVER}/user/fetch`, {
        headers: headers,
        params:params
      })
      .then(res => {

        callback(res.data);
      })
      .catch(console.log);
  };
};


export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userLogin')
    setAuthToken(false)
    // dispatch(actLoginUser({}));
  }
}
